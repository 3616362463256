import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Typography, Container } from '@mui/material';

import {jwtDecode } from 'jwt-decode'; // Install via npm install jwt-decode

function Login() {
    const [formData, setFormData] = useState({ username: '', password: '' });
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        // Use the environment variable for the backend API URL
        //const apiUrl = process.env.REACT_APP_API_URL;
        const apiUrl = process.env.REACT_APP_API_URL || 'https://old.landscapingsoftwarepro.com/backend';
        console.log(apiUrl);
        
        console.log('Form data before sending request:', formData);

        // Send login request to backend
        const response = await axios.post(`${apiUrl}/login`, formData);
        console.log('Backend response:', response.data);

        // Destructure accessToken from the response
        const { accessToken } = response.data;

        if (!accessToken) {
            setMessage('Invalid server response. Please try again.');
            return;
        }

        // Save token to localStorage
        localStorage.setItem('token', accessToken);

        // Decode access token
        const decodedToken = jwtDecode(accessToken);
        console.log('Decoded token:', decodedToken);

        // Check admin status and navigate accordingly
        if (decodedToken.isAdmin) {
            setMessage('Admin login successful!');
            navigate('/admin');
        } else {
            setMessage('User login successful!');
            navigate('/user-dashboard');
        }
    } catch (error) {
        console.error('Login error:', error);
        setMessage(error.response?.data?.error || 'Error occurred during login');
    }
};

    return (
        <Container maxWidth="xs">
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" textAlign="center" gutterBottom>
                    Sign In
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Username"
                        name="username"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.username}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Password"
                        name="password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.password}
                        onChange={handleChange}
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Login
                    </Button>
                </form>
                {message && <Typography color={message.includes('successful') ? 'primary' : 'error'} sx={{ mt: 2 }}>
                    {message}
                </Typography>}
            </Box>
        </Container>
    );
}

export default Login;
