import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Fab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';

function Services() {
    const [services, setServices] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentService, setCurrentService] = useState(null);

    // Base URL for API calls
    const baseURL = process.env.REACT_APP_API_URL || 'https://old.landscapingsoftwarepro.com/backend';

    useEffect(() => {
        fetchServices();
    }, []);

    // Fetch all services from the backend
    const fetchServices = async () => {
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing. Please log in again.');

            const response = await axios.get(`${baseURL}/services`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setServices(response.data);
        } catch (error) {
            console.error('Error fetching services:', error.response?.data || error.message);
        }
    };

    // Open the modal dialog for adding or editing services
    const handleClickOpen = (service = null) => {
        setCurrentService(service);
        setOpen(true);
    };

    // Close the modal dialog
    const handleClose = () => {
        setOpen(false);
        setCurrentService(null);
    };

    // Update the state for form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentService({ ...currentService, [name]: value });
    };

    // Save the service to the database
    const handleSaveService = async () => {
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing. Please log in again.');

            if (currentService?.id) {
                // Update existing service
                await axios.put(`${baseURL}/services/${currentService.id}`, currentService, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            } else {
                // Add new service
                await axios.post(`${baseURL}/services`, currentService, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            }
            fetchServices(); // Refresh the services table
            handleClose(); // Close the modal
        } catch (error) {
            console.error('Error saving service:', error.response?.data || error.message);
        }
    };

    // Delete a service from the database
    const handleDeleteService = async (id) => {
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing. Please log in again.');

            await axios.delete(`${baseURL}/services/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchServices(); // Refresh the services table
        } catch (error) {
            console.error('Error deleting service:', error.response?.data || error.message);
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Services
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Duration</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {services.map((service) => (
                            <TableRow key={service.id}>
                                <TableCell>{service.name}</TableCell>
                                <TableCell>{service.description}</TableCell>
                                <TableCell>${Number(service.price).toFixed(2)}</TableCell>
                                <TableCell>{service.duration}</TableCell>
                                <TableCell>
                                    <IconButton color="primary" onClick={() => handleClickOpen(service)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton color="secondary" onClick={() => handleDeleteService(service.id)}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => handleClickOpen()}>
                <Add />
            </Fab>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{currentService?.id ? 'Edit Service' : 'Add Service'}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Name"
                        name="name"
                        fullWidth
                        margin="normal"
                        value={currentService?.name || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Description"
                        name="description"
                        fullWidth
                        margin="normal"
                        value={currentService?.description || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Price"
                        name="price"
                        fullWidth
                        margin="normal"
                        type="number"
                        value={currentService?.price || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Duration"
                        name="duration"
                        fullWidth
                        margin="normal"
                        value={currentService?.duration || ''}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveService} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default Services;
