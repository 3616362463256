import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';

const Tickets = () => {
    const [tickets, setTickets] = useState([]);

    // Base URL for API calls
    const baseURL = process.env.REACT_APP_API_URL || 'https://old.landscapingsoftwarepro.com/backend';

    useEffect(() => {
        fetchTickets();
    }, []);

    const fetchTickets = async () => {
        try {
            const token = localStorage.getItem('accessToken') || localStorage.getItem('token');
            if (!token) throw new Error('Access token missing. Please log in again.');

            const response = await axios.get(`${baseURL}/tickets`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setTickets(response.data);
        } catch (error) {
            console.error('Error fetching tickets:', error.response?.data || error.message);
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Tickets
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Client Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Priority</TableCell>
                            <TableCell>Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tickets.map((ticket) => (
                            <TableRow key={ticket.id}>
                                <TableCell>{ticket.title}</TableCell>
                                <TableCell>{ticket.client_name}</TableCell>
                                <TableCell>{ticket.status}</TableCell>
                                <TableCell>{ticket.priority}</TableCell>
                                <TableCell>{new Date(ticket.created_at).toLocaleDateString()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default Tickets;
